/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import RequireAuth from 'commons/auth/RequireAuth'

import DaftarPGPage from './containers/DaftarPGPage'
import DetailPGPage from './containers/DetailPGPage'
import TambahPGPage from './containers/TambahPGPage'
import FormPilihPayment from './components/FormPilihPayment'
import FormCreditCard from './components/FormCreditCard'
import FormRetailOutlet from './components/FormRetailOutlet'

const paymentGatewayRoutes = [
  {
    path: '/viapaymentgateway/add',
    element: <TambahPGPage />,
  },
  {
    path: '/viapaymentgateway/detail/:id',
    element: <DetailPGPage />,
  },
  {
    path: '/viapaymentgateway/list',
    element: <DaftarPGPage />,
  },
  {
    path: '/viapaymentgateway/paymentchoice',
    element: <FormPilihPayment />,
  },
  {
    path: '/viapaymentgateway/creditcard/add',
    element: <FormCreditCard />,
  },
  {
    path: '/viapaymentgateway/retailoutlet/add',
    element: <FormRetailOutlet />,
  },
]

export default paymentGatewayRoutes
